import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    surveys: {
        selfEval: {
            engagement: 'Q2 Performance Review',
            progress: 33,
            isSubmitted: false,
        },
        outgoing: {
            type1: [
                {
                    reviewer: {fullName: 'James Harden'},
                    reviewee: {fullName: 'Me'},
                    engagement: 'Q1 Performance Review',
                    isSubmitted: false,
                },
                {
                    reviewer: {fullName: 'Jane Smith'},
                    reviewee: {fullName: 'Me'},
                    engagement: 'Q2 Performance Review',
                    isSubmitted: false,
                },
                {
                    reviewer: {fullName: 'Mark Johnson'},
                    reviewee: {fullName: 'Me'},
                    engagement: 'Q3 Performance Review',
                    isSubmitted: true,
                },
                {
                    reviewer: {fullName: 'Emma Brown'},
                    reviewee: {fullName: 'Me'},
                    engagement: 'Q4 Performance Review',
                    isSubmitted: false,
                },
                {
                    reviewer: {fullName: 'Michael Lee'},
                    reviewee: {fullName: 'Me'},
                    engagement: 'Q2 Performance Review',
                    isSubmitted: true,
                }
            ],
            type2: [
                {
                    reviewer: {fullName: 'Malik Jones'},
                    reviewee: {fullName: 'Me'},
                    engagement: 'Project Omega',
                    isSubmitted: false,
                },
                {
                    reviewer: {fullName: 'Michael Lee'},
                    reviewee: {fullName: 'Me'},
                    engagement: 'Project Alpha',
                    isSubmitted: false,
                },
                {
                    reviewer: {fullName: 'Emma Brown'},
                    reviewee: {fullName: 'Me'},
                    engagement: 'Project Beta',
                    isSubmitted: false,
                },
                {
                    reviewer: {fullName: 'Mark Johnson'},
                    reviewee: {fullName: 'Me'},
                    engagement: 'Q2 Project Review',
                    isSubmitted: true,
                }
            ],
        },
        incoming: {
            type1: [
                {
                    reviewer: 'Me',
                    reviewee: {fullName: {fullName: 'Wade Tang'}},
                    engagement: 'Q3 Performance Review',
                    progress: 0,
                    isSubmitted: false,
                },
                {
                    reviewer: 'Me',
                    reviewee: {fullName: {fullName: 'Austin Ellis'}},
                    engagement: 'Q1 Performance Review',
                    progress: 34,
                    isSubmitted: false,
                }
            ],
            type2: [
                {
                    reviewer: 'Me',
                    reviewee: {fullName: {fullName: 'Willa Walker'}},
                    engagement: 'Project Alpha',
                    progress: 82,
                    isSubmitted: false,
                },
                {
                    reviewer: 'Me',
                    reviewee: {fullName: {fullName: 'Tate Jensen'}},
                    engagement: 'Project Omega',
                    progress: 30,
                    isSubmitted: false,
                },
                {
                    reviewer: 'Me',
                    reviewee: {fullName: {fullName: 'Aya Walter'}},
                    engagement: 'Project Gamma',
                    progress: 0,
                    isSubmitted: false,
                },
                {
                    reviewer: 'Me',
                    reviewee: {fullName: {fullName: 'Claire Leach'}},
                    engagement: 'Q2 Project Review',
                    progress: 20,
                    isSubmitted: false,
                }
            ]
        }
    }
};

/* eslint-enable */

// ------------------------------------------------
// GET: Return surveys
// ------------------------------------------------
mock.onGet('/prs/surveys').reply(config => {
    // eslint-disable-next-line object-curly-newline
    const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        status = null,
    } = config.params
    /* eslint-enable */


    return [
        200,
        {
            surveys: data.surveys,
            total: 2,
        },
    ]
})

// ------------------------------------------------
// POST: Add new survey
// ------------------------------------------------
mock.onPost('/prs/surveys').reply(config => {
    // Get event from post data
    const { survey } = JSON.parse(config.data)

    // Assign Status
    survey.status = 'active'

    const { length } = data.surveys.incomingRequests
    let lastIndex = 0
    if (length) {
        lastIndex = data.surveys.incomingRequests[length - 1].id
    }
    survey.id = lastIndex + 1

    data.surveys.incomingRequests.push(survey)

    return [201, { survey }]
})

// ------------------------------------------------
// GET: Return Single survey
// ------------------------------------------------
mock.onGet(/\/prs\/surveys\/\d+/).reply(config => {
    // Get event id from URL
    let surveyId = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    surveyId = Number(surveyId)

    const surveyIndex = data.surveys.incomingRequests.findIndex(e => e.id === surveyId)
    const survey = data.surveys.incomingRequests[surveyIndex]

    if (survey) return [200, survey]
    return [404]
})
