export default [
    {
        path: '/',
        name: 'prs-main',
        component: () => import('@/views/prs/Main.vue'),
        meta: {
            layout: 'horizontal',
            resource: 'UserAccess',
            action: 'read',
        }
    },

    // Admin View (to remove maybe)
    // {
    //     path: '/admin',
    //     name: 'prs-admin',
    //     component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    //     meta: {
    //         resource: 'AdminAccess',
    //         layout: 'horizontal',
    //     }
    // },

]
