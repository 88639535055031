// results routes
export default [
  {
    path: '/admin/results',
    name: 'results-list',
    component: () => import('@/views/prs/results/results-list/ResultsList.vue'),
    meta: {
      resource: 'PartnerAccess',
      layout: 'horizontal',
      breadcrumb: [
        {
          text: 'Results List',
          active: true,
        },
      ],
    }
  },
]
